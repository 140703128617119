// import logo from "../assets/images/logo.png";
import {setAppConfig} from "common-frontend-components";

const apiUrl = process.env.REACT_APP_STAGE === 'local' ? "http://localhost:4000/dev" : "https://api.esemes.today";

export const appConfig = setAppConfig({
  apiUrl,
  accountUrl: `${apiUrl}/accounts`,
  wwwUrl: `${apiUrl}/www`,
  versionUrl: `https://artomanut-version.s3.amazonaws.com/version.json`,
  reCaptchaSiteKey: '6LfMJ9kbAAAAAMrRJdRVo4DyuUaS0Eq9-LGdPEwK',
  googleLoginClientId: '',
  google_maps_key: '',
  ipinfo_token: "",
  company: 'Artomanut Ltd',
  website: 'artomanut.net',
  app_name: 'artomanut',
  app_slogan: 'The Best Alternative Medicine',
  app_description: 'set an appointment and get treated with good hands',
  terms_last_update: "April 16 2019",
  desktopMinWidth: 1200,
  // logo,
  googleAnalyticsTrackingId: "",
  gaCode: "",
})
