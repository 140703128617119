import React, {FC} from 'react';

export const Topbar:FC = () => {
  return (
    <div id="topbar">
      <div className="top-bar-right">
        <i className="fas fa-leaf"/>
      </div>
      <div className="text-logo">
        <h2>ART OMANUT</h2>
      </div>
      <div className="social-media">
        <a href="#contact">
          <i className="fas fa-envelope"/>
        </a>
        <a
          href="https://www.google.com/maps/place/%D7%90%D7%A8%D7%98+%D7%90%D7%9E%D7%A0%D7%95%D7%AA+-+Art+Omanut%E2%80%AD/@31.7966461,34.7275286,19z/data=!4m5!3m4!1s0x0:0x6523b8738712d8ec!8m2!3d31.7968615!4d34.7271745?authuser=6"
          target="_blank"
        >
          <i className="fa fa-map-marker"/>
        </a>
      </div>
    </div>
  )
}
