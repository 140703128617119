import {routes as baseRoutes} from "common-frontend-components";

export const routes = {
  home: '/',
  about: '/about',
  book: '/book',
  clinic: '/clinic',
  contact: '/contact',
  gallery: '/gallery',
  shop: '/shop',
}
