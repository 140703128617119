import React, {FC} from 'react';

export const Footer2:FC = () => {
  return (
    <footer>
      <div id="footer-box">
        <div className="footer-nav">
          <a href="#header"><i className="fas fa-chevron-circle-up"/></a>
        </div>
        <h2>ART OMANUT</h2>
        <div className="footer-media">
          <i className="fab fa-instagram"/>
          <i className="fab fa-facebook"/>
          <i className="fas fa-envelope"/>
          <i className="fas fa-phone"/>
          <a
            href="https://www.google.com/maps/place/%D7%90%D7%A8%D7%98+%D7%90%D7%9E%D7%A0%D7%95%D7%AA+-+Art+Omanut%E2%80%AD/@31.7966461,34.7275286,19z/data=!4m5!3m4!1s0x0:0x6523b8738712d8ec!8m2!3d31.7968615!4d34.7271745?authuser=6">
            <i className="fa fa-map-marker"/>
          </a>
        </div>
      </div>
    </footer>
  )
}