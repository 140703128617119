import React, {FC} from 'react';
import {Topbar} from "../components/appbars/Topbar";
import messageSrc from '../assets/images/massage.png';
import acupanctureSrc from '../assets/images/acupancture.png';
// import artOmanutSrc from '../assets/images/art-omanut.jpg';
import homeopathySrc from '../assets/images/homeopathy.png';
import artOmanutSrc from '../assets/images/homeopathy.png';
import bachSrc from '../assets/images/bach.png';
import {Link} from 'react-router-dom';
import {routes} from "../variables/routes";
import {Footer2} from "../components/footers/Footer2";
import {SideBar} from "../components/appbars/SideBar";

export const HomePage:FC = () => {
  return (
    <>
      <Topbar/>
      <SideBar/>

      {/* HOME CONTENT */}
      <div id="content">
        <div id="header">
          <div className="home-header-card">
            <i className="fas fa-hand-holding-medical"/>
            <h1>ענת בר אילן</h1>
            <h2>מטפלת רב תחומית</h2>
            <p>
              הומאופתיה קלאסית | עיסוי רפואי | דיקור סיני | <br/>צמחי מרפא | פרחי באך | ארומתרפיה
              <br />
              <br />
              רפואה עממית טיבטית - דיקור מחט אחת | <br/> עיסוי רקמות עמוקות | כוסות רוח | קערות טיבטיות
            </p>
          </div>
          {/*<img src="assets/Anat.jpg" alt="card"/> */}
        </div>
        <div className="">

        </div>

        {/*clinic-options*/}
        <div className="curved-up">
          <div className="clinic-round-card">
            <h2>הקליניקה</h2>
            <Link to={routes.contact}>
              <i className="fas fa-map-marker-alt"/>
            </Link>
            <p>ממוקמת בפינת רחוב החיילים במושב ביצרון במתחם מגודר עם חנייה נגישה וזמינה לאורך כל שעות
              היום.
            </p>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#9189da" fill-opacity="1"
                  d="M0,160L48,181.3C96,203,192,245,288,261.3C384,277,480,267,576,224C672,181,768,107,864,69.3C960,32,1056,32,1152,42.7C1248,53,1344,75,1392,85.3L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
            </path>

          </svg>
        </div>

        <div id="clinic">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={messageSrc}/>
                <h2>עיסוי טיבטי רפואי</h2>
                <p>עיסוי רקמות עמוקות על פי חמשת היסודות</p>
              </div>
              <div className="flip-card-back">
                <p>
                  עיסוי טיבטי מסורתי נחשב למרכיב בלתי נפרד ברפואה הטיבטית, המשמש מאות שנים הן במניעה והן בטיפול במחלות.
                </p>
                <p>
                  כאחת משיטות הריפוי המסורתיות הוותיקות בעולם, הרפואה הטיבטית עוקבת אחר מערכת הוליסטית המשלבת את צרכי הגוף והנפש.
                </p>
                <p>
                  הוא מבוסס על ההנחה כי הבריאות מושגת על ידי שמירה על האיזון של חמשת היסודות הבסיסיים שלנו: <br/> אדמה, מים, אש, רוח ומתכת. כשיש חוסר איזון, יש חוסר בריאות.
                </p>
                <a href="#contact">
                  <div className="flip-card-clinic-info">
                    <p>מפגש ראשון - שעה וחצי - 350<br/>
                      מפגש מעקב - שעה - 300</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={acupanctureSrc}/>
                <h2>דיקור</h2>
                <p>דיקור סיני מסורתי
                  <br/>
                  דיקור טיבטי - שיטת מחט אחת
                </p>
              </div>
              <div className="flip-card-back">
                <p>
                  דיקור טיבטי הוא מערכת ייחודית, אם כי היא חולקת כמה תכונות עם הטכניקה הרפואית הסינית המסורתית הידועה יותר
                </p>
                <p>
                  בעוד שהדיקור הטיבטי והרפואה הסינית המסורתית שניהם משתמשים במחטים כדי לעורר את זרימת האנרגיה בגוף השוני ביניהם הוא אבחון על פי הדופק.
                </p>
                <p>
                  טכניקה עדינה אך יעילה זו יכולה לתקן חוסר איזון הנגרם כתוצאה מעודף, מחסור או חסימת אנרגיה בערוצים ובאיבריהם המחוברים.
                </p>
                <a href="#contact">
                  <div className="flip-card-clinic-info">
                    <p>מפגש ראשון - שעה וחצי - 300<br/>
                      מפגש מעקב - שעה - 250</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={homeopathySrc}/>
                <h2>הומאופתיה</h2>
                <p>הומאופתיה קלאסית</p>
              </div>
              <div className="flip-card-back">
                <p>
                  הומיאופתיה היא דרך טיפול רפואית שמשתמשת בתרופות טבעיות כדי לעורר את כוחות הריפוי העצמי של הגוף.
                </p>
                <p>
                  זהו טיפול הוליסטי הרואה את הגוף והנפש כמכלול
                  ושהאדם כולו זקוק לטיפול, לא רק לסימפטומים מבודדים של מחלה מוגדרת.
                </p>
                <p>
                  החומרים המשמשים מדוללים במיוחד ומופעלים באופן שאינו גורם לתופעות לוואי ביולוגיות.
                </p>
                <a href="#contact">
                  <div className="flip-card-clinic-info">
                    <p>מפגש ראשון - שעה וחצי - 650<br/>
                      מפגש מעקב - שעה - 450
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img src={bachSrc}/>
                <h2>צמחי מרפא</h2>
                <p>צמחי מרפא ופרחי באך</p>
              </div>
              <div className="flip-card-back">
                <p>
                  הטיפול בפרחי באך יכול לסייע בטיפול בעיות פיזיות ופסיכולוגיות.
                </p>
                <p>
                  ד”ר באך האמין שלמצב הנפשי תפקיד חשוב בשמירת הבריאות הפיזית של האדם. בהכירו את נפש האדם, תר ד”ר באך אחר תמציות פרחים, אשר ישפיעו מבחינה אנרגטית- נפשית.
                </p>
                <p>
                  טכניקת הטיפול בפרחי באך מצריכה היכרות עמוקה עם המטופל, ולפיכך משלבת מספר טכניקות אבחון חשובות. התמציות, שהן טבעיות לחלוטין, ניתנות למטופל כתרכובת ספציפית, המותאמת עבורו ועבור הפתולוגיה המסוימת בה מעוניינים לטפל.
                </p>
                <a href="#contact">
                  <div className="flip-card-clinic-info">
                    <p>מפגש ראשון - שעה וחצי - 450<br/>
                      מפגש מעקב - שעה - 200</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="curved-bottom">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#e5e5e5" fill-opacity="1"
                  d="M0,160L48,181.3C96,203,192,245,288,261.3C384,277,480,267,576,224C672,181,768,107,864,69.3C960,32,1056,32,1152,42.7C1248,53,1344,75,1392,85.3L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
            </path>
          </svg>
        </div>
        <div id="about">
          <h1>ענת בר אילן</h1>
          <div className="info">
            <ul className="info-studies">
              <li>1995-1999 רפואה עממית טיבטית: עיסוי, דיקור, כוסות רוח, קערות טיבטיות. <br/>
                בי"ס מעשה הרפואה בניהולה של אורני זקס.</li>
              <li>1995 קורס עיסוי וארומתרפיה. בי"ס מעשה הרפואה.</li>
              <li>1995 קורס פרחי באך. בהוראת ד"ר מיכל יקיר.</li>
              <li>1996-1998 קורס צמחי מרפא. בהוראת מינה פארן, בית שמואל ירושלים.</li>
              <li>1998 קורס רפואה סינית: דיקור סיני. בהוראת דניאל יעקובסון.</li>
              <li>2009-2013 הומאופתיה קלאסית. בהנהלת ד"ר חיים רוזנטל.
                אוניברסיטת ת"א קמפוס ברושים.</li>
            </ul>

            <ul className="info-practice">
              <li>1995 עד היום - פרקטיקה בקליניקה: עיסוי רפואי, ארומתרפיה ופרחי באך.</li>
              <li>1998 עד היום - פרקטיקה בקליניקה: בצמחי מרפא, רפואה עממית טיבטית, סינית ודיקור.</li>
              <li>2002 מורה בקליניקה שלי - קורס פרחי באך, 3 חודשים.</li>
              <li>2007 מורה בקורס תנועה לשקט למורות, קרן קרב - קורס פרחי באך, 3 חודשים.</li>
              <li>
                2008-2009 מורה בקליניקה שלי 4 קורסים עוקבים של 3 חודשים: עיסוי וארומתרפיה, פרחי באך, תרופות סבתא, וצמחי מרפא
              </li>
              <li>דצמבר 2013 ועד היום - פרקטיקה בהומאופתיה קלאסית</li>
            </ul>
          </div>
        </div>


        <div className="contact-curve">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#9189da" fill-opacity="1"
                  d="M0,288L120,250.7C240,213,480,139,720,112C960,85,1200,107,1320,117.3L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z">
            </path>
          </svg>
        </div>
        <div id="contact">
          <div id="contact-director">
            <div className="content-wrap">
              <div className="contact-wrap gallery">
                <h2>גלריית תמונות</h2>
                <a href="https://www.google.com/maps/place/%D7%A2%D7%A0%D7%AA+%D7%91%D7%A8+%D7%90%D7%99%D7%9C%D7%9F+-+Art+Omanut%E2%80%AD/@31.7967743,34.7270875,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipPEG_bDkVAoE-LhJX2pWsQgdLGdICdWHLB4_DFD!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipPEG_bDkVAoE-LhJX2pWsQgdLGdICdWHLB4_DFD%3Dw203-h152-k-no!7i4032!8i3024!4m7!3m6!1s0x0:0x6523b8738712d8ec!8m2!3d31.7968615!4d34.7271745!14m1!1BCgIgAQ?authuser=6" target="_blank">
                  <img src={artOmanutSrc} alt="bussines-uploaded-images"/>
                </a>
                {/*<div className="images row-a">*/}
                {/*    <a href=""><i className="fas fa-images"></i></a>*/}
                {/*    <a href=""><i className="fas fa-images"></i></a>*/}
                {/*</div>*/}
                {/*<div className="images row-b">*/}
                {/*    <a href=""><i className="fas fa-images"></i></a>*/}
                {/*    <a href=""><i className="fas fa-images"></i></a>*/}
                {/*</div>*/}
              </div>

              <div className="contact-wrap contact-info">
                <div className="contact-info-media">
                  <div className="contact-info-media-wrap message-whatsapp-feature">
                    <a href="https://api.whatsapp.com/send?phone=972508837011&text=" target="_blank">
                      <i className="far fa-comment-dots"/>
                    </a>
                  </div>
                  <div className="contact-info-media-wrap">
                    <i className="fas fa-at"/>
                  </div>
                  <div className="contact-info-media-wrap">
                    <i className="fas fa-map-marked-alt"/>
                  </div>
                </div>
                <div className="contact-info-details">
                  <a href="tel:050-883-7011"><h3>050-883-7011</h3></a>
                  <h3>ant857@gmail.com</h3>
                  <h3>מושב ביצרון, החיילים 40 תד. 129, 6094600</h3>
                </div>
              </div>

              <div className="contact-wrap mapouter">
                <div className="gmap_canvas">
                  <iframe width="500" height="500" id="gmap_canvas"
                          src="https://maps.google.com/maps?q=art%20omanut&t=&z=15&ie=UTF8&iwloc=&output=embed"
                          frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2/>

      {/* media credits */}
      <a href="https://icons8.com/icon/112375/herbal-medicine">Herbal Medicine icon by Icons8</a>

      {/* <i className="fab fa-shopify"/> */}
    </>
  )
}
