import React, {FC} from "react";

export const SideBar: FC = () => {
  return (
    <div id="side-nav">
      <div className="side-tab">
        <a href="#content"><i className="fas fa-home"/>
          <h4>דף הבית</h4>
        </a>
      </div>
      <div className="side-tab">
        <a href="#clinic"><i className="fas fa-mortar-pestle"/>
          <h4>קליניקה</h4>
        </a>
      </div>
      <div className="side-tab">
        <a href="#about"><i className="fas fa-info-circle"/>
          <h4>אודות</h4>
        </a>
      </div>
      <div className="side-tab">
        <a href="#contact-director"><i className="fas fa-image"/>
          <h4>גלריה</h4>
        </a>
      </div>
      <div className="side-tab">
        <a href="#contact-director"><i className="fas fa-phone"/>
          <h4>050-883-7011</h4>
        </a>
      </div>
    </div>
  )
}