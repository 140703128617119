// workers & report
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import reportWebVitals from "../reportWebVitals";

// react & router
import React, {FC, ReactNode, StrictMode} from 'react';
import {Switch, Redirect, useLocation, Route} from "react-router-dom";

// hooks & providers
import {
  useSizeListener,
  useScrollTransition,
  useSizes,
  BrowserRouterProvider,
} from "common-frontend-components";
import {useConnectRouterStore} from "common-frontend-components";
import {LanguageProvider} from "../components/providers/LanguageProvider";

// styles
import '../assets/styles/style.css';

// routes
import { routes } from "../variables/routes";
import {HomePage} from "../views/HomePage";
import {AboutPage} from "../views/AboutPage";
import {BookPage} from "../views/BookPage";
import {ClinicPage} from "../views/ClinicPage";
import {ContactPage} from "../views/ContactPage";
import {GalleryPage} from "../views/GalleryPage";
import {ShopPage} from "../views/ShopPage";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const Providers:FC<{children:ReactNode}> = ({children}) => {
  return (
    <StrictMode>
      <BrowserRouterProvider>
        <LanguageProvider>
          {children}
        </LanguageProvider>
      </BrowserRouterProvider>
    </StrictMode>
  )
}

const Hooks:FC<{children:ReactNode}> = ({children}) => {
  useConnectRouterStore();
  useSizeListener();
  useScrollTransition();
  useSizes();
  return <>{children}</>;
}

const Routes:FC = () => {
  const { pathname } = useLocation();
  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

      <Route path={routes.home} component={HomePage} exact/>
      {/*<Route path={routes.about} component={AboutPage} exact/>*/}
      {/*<Route path={routes.book}  component={BookPage} exact/>*/}
      {/*<Route path={routes.clinic} component={ClinicPage} exact/>*/}
      {/*<Route path={routes.contact} component={ContactPage} exact/>*/}
      {/*<Route path={routes.gallery} component={GalleryPage} exact/>*/}
      {/*<Route path={routes.shop} component={ShopPage} exact/>*/}

      <Redirect from="*" to={routes.home} />
    </Switch>
  )
}

const WwwApp:FC = () => {
  return (
    <Providers>
      <Hooks>
        <Routes/>
      </Hooks>
    </Providers>
  );
}

export { WwwApp };
